import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Leitura from './Components/Leitura/Leitura';
import Final from './Components/Final/Final';
import { PhotoProvider } from './contexts/PhotoContext';
import './App.css';

function App() {
  return (
    <Router>
      <PhotoProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={
              <div className="min-h-screen bg-slate-800 flex flex-col items-center justify-center">
                <h1 className="text-3xl text-amber-100 font-serif mb-8">
                  Bem-vindo ao Mapa do Destino
                </h1>
                <a
                  href="/leitura"
                  className="bg-teal-400 hover:bg-teal-500 text-white font-medium rounded-full px-8 py-4"
                >
                  Começar Leitura
                </a>
              </div>
            } />
            <Route path="/leitura" element={<Leitura />} />
            <Route path="/final" element={<Final />} />
          </Routes>
        </div>
      </PhotoProvider>
    </Router>
  );
}

export default App;
