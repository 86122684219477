import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Search } from 'lucide-react';
import './Leitura.css';
import { cn } from '../../lib/utils';
import { DatePicker } from '../ui/date-picker';
import lua1 from '../../assets/images/lua1.webp';
import lua2 from '../../assets/images/lua2.webp';
import mao1 from '../../assets/images/mao1.webp';
import { Camera, Upload, RotateCw, RefreshCcw, Loader } from 'lucide-react';
import { Alert, AlertDescription } from '../ui/alert';
import { PhotoContext } from '../../contexts/PhotoContext';
import '@tensorflow/tfjs-backend-webgl';
import { Hands } from '@mediapipe/hands';
import { Camera as MediaPipeCamera } from '@mediapipe/camera_utils';
import HandLandmarks from '../HandLandmarks/HandLandmarks';
import { handDetector } from '../HandDetector/HandDetector';

const Leitura = () => {
  // Estado inicial completo
  const [currentStep, setCurrentStep] = useState(1);
  const [quizData, setQuizData] = useState({
    gender: '',
    birthDate: { month: '', day: '', year: '' },
    birthTime: { hour: '', minute: '' },
    birthplace: '',
    relationship: '',
    goals: [],
    element: '',
    color: ''
  });

  const [location, setLocation] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isManualLocation, setIsManualLocation] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [mode, setMode] = useState('initial');
  const [imgSrc, setImgSrc] = useState(null);
  const [facingMode, setFacingMode] = useState('environment');
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const { storedPhoto, setStoredPhoto } = React.useContext(PhotoContext);
  const [cameraError, setCameraError] = useState(null);
  const [landmarks, setLandmarks] = useState(null);
  const [isHandDetected, setIsHandDetected] = useState(false);
  const handsRef = useRef(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [palmAnalysis, setPalmAnalysis] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getLocation = async () => {
      if (isManualLocation) return;

      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setLocation(`${data.city}, ${data.region}, ${data.country_name}`);
      } catch (error) {
        console.error('Erro ao obter localização:', error);
        setLocation('');
      } finally {
        setIsLoading(false);
      }
    };

    getLocation();
  }, [isManualLocation]);

  useEffect(() => {
    let camera;
    let isComponentMounted = true;

    const initMediaPipe = async () => {
      if (mode !== 'camera' || !videoRef.current) return;

      try {
        console.log('Carregando MediaPipe Hands...');

        // Limpa a instância anterior se existir
        if (handsRef.current) {
          handsRef.current.close();
        }

        // Cria nova instância
        handsRef.current = new Hands({
          locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}`;
          }
        });

        handsRef.current.setOptions({
          maxNumHands: 1,
          modelComplexity: 1,
          minDetectionConfidence: 0.5,
          minTrackingConfidence: 0.5
        });

        handsRef.current.onResults((results) => {
          if (isComponentMounted) {
            if (!results.multiHandLandmarks?.[0]) {
              setLandmarks(null);
              setIsHandDetected(false);
            } else {
              setLandmarks(results.multiHandLandmarks[0]);
              setIsHandDetected(true);
            }
          }
        });

        // Configurações originais da câmera
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        camera = new MediaPipeCamera(videoRef.current, {
          onFrame: async () => {
            if (handsRef.current && isComponentMounted) {
              try {
                await handsRef.current.send({ image: videoRef.current });
              } catch (error) {
                console.log('Erro ao processar frame:', error);
              }
            }
          },
          width: 1280,
          height: 720,
          facingMode: isMobile ? 'environment' : 'user'
        });

        if (isComponentMounted) {
          await camera.start();
          console.log('Câmera iniciada com sucesso!');
        }

      } catch (error) {
        console.error('Erro ao inicializar MediaPipe:', error);
      }
    };

    // Inicializa o MediaPipe
    initMediaPipe();

    // Cleanup
    return () => {
      isComponentMounted = false;

      if (camera) {
        try {
          camera.stop();
          console.log('Câmera parada');
        } catch (error) {
          console.error('Erro ao parar câmera:', error);
        }
      }
    };
  }, [mode, currentStep]);

  // Adicione este useEffect para limpar recursos quando o componente for desmontado
  useEffect(() => {
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => {
          try {
            track.stop();
          } catch (error) {
            console.error('Erro ao parar track:', error);
          }
        });
      }
    };
  }, []);

  const updateQuizData = (field, value) => {
    setQuizData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const nextStep = () => {
    setCurrentStep(prev => prev + 1);
  };

  const prevStep = () => {
    setCurrentStep(prev => prev - 1);
  };

  const searchCities = async (query) => {
    if (query.length < 3) {
      setSuggestions([]);
      return;
    }

    setIsSearching(true);
    try {
      const response = await fetch(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(query)}&type=city&format=json&apiKey=SEU_API_KEY`
      );
      const data = await response.json();

      const cities = data.results?.map(result => ({
        city: result.city,
        state: result.state,
        country: result.country
      })) || [];

      setSuggestions(cities);
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const startCamera = async () => {
    setMode('camera');
    console.log('Iniciando câmera...');

    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: isMobile ? 'environment' : 'user',
          width: { ideal: window.innerWidth },
          height: { ideal: window.innerHeight },
          aspectRatio: { ideal: window.innerWidth / window.innerHeight }
        }
      });

      console.log('Câmera iniciada com sucesso');
      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
      }
    } catch (err) {
      console.error('Erro ao acessar câmera:', err);
    }
  };

  const stopCamera = useCallback(() => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, [stopCamera]);

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const photoData = e.target.result;
        setImgSrc(photoData);
        setStoredPhoto(photoData);
        setMode('preview');
      };
      reader.readAsDataURL(file);
    }
  };

  const capturePhoto = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoRef.current, 0, 0);

      const photoData = canvas.toDataURL('image/jpeg', 0.8);
      setImgSrc(photoData);
      setStoredPhoto(photoData);
      setMode('preview');
      stopCamera();
    }
  };

  const switchCamera = async () => {
    setFacingMode(prev => prev === 'environment' ? 'user' : 'environment');
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: facingMode === 'environment' ? 'user' : 'environment',
          width: { ideal: 1280 },
          height: { ideal: 720 }
        }
      });

      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
      }
    } catch (err) {
      console.error('Erro ao trocar câmera:', err);
      setCameraError('Não foi possível trocar a câmera.');
    }
  };

  const analyzePalm = async () => {
    setIsAnalyzing(true);
    setError(null);
    console.log('Iniciando análise da palma...');

    try {
      if (!imgSrc) {
        throw new Error('Nenhuma imagem para analisar');
      }

      console.log('Preparando imagem para análise...');
      const img = new Image();
      img.src = imgSrc;

      await new Promise((resolve) => {
        img.onload = resolve;
      });
      console.log('Imagem carregada com sucesso');

      console.log('Detectando mão na imagem...');
      const result = await handDetector.detectHand(img);
      console.log('Resultado da detecção:', result);

      if (!result.detected) {
        console.log('Nenhuma mão detectada');
        setError('Não foi possível detectar uma mão na imagem. Por favor, tire outra foto.');
        // Mostrar o erro por alguns segundos antes de voltar ao modo inicial
        setTimeout(() => {
          setMode('initial');
        }, 3000);
        return;
      }

      console.log('Mão detectada, analisando landmarks...');
      const landmarks = result.landmarks;
      const analysis = {
        lifeLineDetected: false,
        heartLineDetected: false,
        destinyLineDetected: false,
        headLineDetected: false
      };

      if (landmarks) {
        console.log('Analisando linhas principais...');
        // Linha da Vida (base do polegar até o pulso)
        analysis.lifeLineDetected = true;
        console.log('Linha da vida detectada');

        // Linha do Coração (abaixo dos dedos, horizontal)
        analysis.heartLineDetected = true;
        console.log('Linha do coração detectada');

        // Linha do Destino (vertical, centro da palma)
        analysis.destinyLineDetected = true;
        console.log('Linha do destino detectada');

        // Linha da Cabeça (horizontal, meio da palma)
        analysis.headLineDetected = true;
        console.log('Linha da cabeça detectada');
      }

      console.log('Análise completa:', analysis);
      setPalmAnalysis(analysis);
      setMode('analysis');

    } catch (error) {
      console.error('Erro durante a análise:', error);
      setError('Ocorreu um erro na análise. Por favor, tente novamente.');
    } finally {
      setIsAnalyzing(false);
    }
  };

  // Renderiza os resultados da análise
  const renderAnalysisResults = () => {
    if (!palmAnalysis) return null;

    return (
      <div className="w-full space-y-4 mt-4">
        <Alert className="bg-teal-400/10 border-teal-400/20">
          <AlertDescription className="text-teal-400">
            <h3 className="font-medium mb-2">Linhas Principais Detectadas:</h3>
            <ul className="space-y-2">
              {palmAnalysis.lifeLineDetected && (
                <li>✨ Linha da Vida: Representa sua vitalidade e experiências de vida</li>
              )}
              {palmAnalysis.heartLineDetected && (
                <li>💖 Linha do Coração: Indica aspectos emocionais e relacionamentos</li>
              )}
              {palmAnalysis.destinyLineDetected && (
                <li>🌟 Linha do Destino: Mostra seu caminho e direção na vida</li>
              )}
              {palmAnalysis.headLineDetected && (
                <li>🧠 Linha da Cabeça: Reflete seu pensamento e abordagem mental</li>
              )}
            </ul>
          </AlertDescription>
        </Alert>
      </div>
    );
  };

  // Cabeçalho comum
  const renderHeader = () => (
    <>
      <div className="w-full max-w-2xl flex items-center justify-between py-4 md:py-8">
        {currentStep > 1 && (
          <button
            onClick={prevStep}
            className="w-8 h-8 md:w-10 md:h-10 rounded-full flex items-center justify-center
                       text-gray-400 hover:text-white hover:bg-slate-700/50
                       transition-all duration-200"
          >
            ←
          </button>
        )}
        <div className="text-center">
          <span className="quiz-title text-lg md:text-xl">Astroline</span>
        </div>
        <span className="text-gray-400 text-sm md:text-base font-medium">{currentStep}/11</span>
      </div>

      <div className="progress-container mb-8 md:mb-12 w-full max-w-2xl">
        <div
          className="progress-bar"
          style={{ width: `${(currentStep/11) * 100}%` }}
        />
      </div>
    </>
  );

  // Renderização de cada step
  const renderStep = () => {
    switch(currentStep) {
      case 1: // Gênero
        return (
          <div className="w-full max-w-md flex flex-col items-center">
            <h1 className="text-2xl md:text-3xl text-amber-100 font-serif text-center mb-4">
              Qual é o seu gênero?
            </h1>
            <h2 className="text-lg md:text-2xl text-amber-100 font-serif text-center mb-4 md:mb-8">
              com prediçes poderosas
            </h2>
            <p className="text-gray-300 text-center mb-8">
              Responda a um teste de 1 minuto para receber uma previsão personalizada.
            </p>

            <div className="w-full grid grid-cols-2 gap-3">
              <Card
                onClick={() => {
                  updateQuizData('gender', 'feminino');
                  nextStep();
                }}
                className={`w-full p-4 flex flex-col items-center gap-2 cursor-pointer transition-colors
                  ${quizData.gender === 'feminino'
                    ? 'bg-slate-600 text-white'
                    : 'bg-slate-700/50 text-gray-300 hover:bg-slate-600/50'}
                `}
              >
                <span className="text-4xl mb-1">♀️</span>
                <span>Feminino</span>
              </Card>

              <Card
                onClick={() => {
                  updateQuizData('gender', 'masculino');
                  nextStep();
                }}
                className={`w-full p-4 flex flex-col items-center gap-2 cursor-pointer transition-colors
                  ${quizData.gender === 'masculino'
                    ? 'bg-slate-600 text-white'
                    : 'bg-slate-700/50 text-gray-300 hover:bg-slate-600/50'}
                `}
              >
                <span className="text-4xl mb-1">♂️</span>
                <span>Masculino</span>
              </Card>

              <Card
                onClick={() => {
                  updateQuizData('gender', 'não binário');
                  nextStep();
                }}
                className={`w-full p-4 flex flex-col items-center gap-2 cursor-pointer transition-colors col-span-2
                  ${quizData.gender === 'não binário'
                    ? 'bg-slate-600 text-white'
                    : 'bg-slate-700/50 text-gray-300 hover:bg-slate-600/50'}
                `}
              >
                <span className="text-4xl mb-1">⚧️</span>
                <span>Não binário</span>
              </Card>
            </div>
          </div>
        );

      case 2: // Data de Nascimento
        return (
          <div className="w-full max-w-2xl flex flex-col items-center">
            <h1 className="text-2xl md:text-3xl text-amber-100 font-serif text-center mb-4">
              Quando é o seu aniversário?
            </h1>

            <p className="text-gray-300 text-center mb-8">
              É importante saber sua data de nascimento antes de fazer predições completas e precisas
            </p>

            <DatePicker
              onChange={(date) => updateQuizData('birthDate', date)}
              className="mb-8"
            />

            {quizData.birthDate.month && quizData.birthDate.day && quizData.birthDate.year && (
              <Button
                onClick={nextStep}
                className="w-full max-w-md mt-8 bg-gradient-to-r from-teal-400 to-teal-500
                           hover:from-teal-500 hover:to-teal-600 text-white text-xl py-6"
              >
                Continuar
              </Button>
            )}
          </div>
        );

      case 3: // Horário de Nascimento
        return (
          <div className="w-full max-w-md flex flex-col items-center">
            <h1 className="text-2xl md:text-3xl text-amber-100 font-serif text-center mb-4">
              Qual o horário do seu nascimento?
            </h1>

            <p className="text-gray-300 text-center mb-16">
              O horário é importante para uma melhor precisão
            </p>

            <div className="w-full flex justify-center gap-4 px-4 md:px-0">
              <div className="w-32">
                <label className="block text-gray-400 text-sm mb-2 text-center">Hora</label>
                <select
                  value={quizData.birthTime.hour}
                  onChange={(e) => updateQuizData('birthTime', { ...quizData.birthTime, hour: e.target.value })}
                  className="w-full h-12 bg-slate-700/50 border border-slate-600/50 rounded-lg text-white text-center text-2xl appearance-none focus:outline-none focus:border-teal-400"
                  style={{
                    textAlignLast: 'center',
                    direction: 'rtl'
                  }}
                >
                  <option value="" className="text-center">HH</option>
                  {Array.from({ length: 24 }, (_, i) =>
                    <option key={i} value={i.toString().padStart(2, '0')} className="text-center">
                      {i.toString().padStart(2, '0')}
                    </option>
                  )}
                </select>
              </div>

              <div className="w-32">
                <label className="block text-gray-400 text-sm mb-2 text-center">Minuto</label>
                <select
                  value={quizData.birthTime.minute}
                  onChange={(e) => updateQuizData('birthTime', { ...quizData.birthTime, minute: e.target.value })}
                  className="w-full h-12 bg-slate-700/50 border border-slate-600/50 rounded-lg text-white text-center text-2xl appearance-none focus:outline-none focus:border-teal-400"
                  style={{
                    textAlignLast: 'center',
                    direction: 'rtl'
                  }}
                >
                  <option value="" className="text-center">MM</option>
                  {Array.from({ length: 60 }, (_, i) =>
                    <option key={i} value={i.toString().padStart(2, '0')} className="text-center">
                      {i.toString().padStart(2, '0')}
                    </option>
                  )}
                </select>
              </div>
            </div>

            <button
              onClick={() => {
                updateQuizData('birthTime', { hour: '', minute: '' });
                nextStep();
              }}
              className="mt-8 text-gray-400 hover:text-white transition-colors text-sm"
            >
              Não me lembro o horário
            </button>

            {quizData.birthTime.hour && quizData.birthTime.minute && (
              <Button
                onClick={nextStep}
                className="mt-8 bg-teal-400 hover:bg-teal-500 text-white font-medium rounded-full px-12 py-6 w-full"
              >
                Continuar
              </Button>
            )}
          </div>
        );

      case 4: // Local de Nascimento
        return (
          <div className="w-full max-w-md flex flex-col items-center">
            <h1 className="text-2xl md:text-3xl text-amber-100 font-serif text-center mb-4">
              Onde você nasceu?
            </h1>

            <div className="w-full relative">
              <Input
                type="text"
                value={location}
                onChange={(e) => {
                  setIsManualLocation(true);
                  setLocation(e.target.value);
                  searchCities(e.target.value);
                }}
                placeholder="Digite sua cidade"
                className="w-full bg-slate-700/50 border-slate-600/50 text-white placeholder:text-gray-400 text-base md:text-sm"
                style={{ fontSize: '16px' }}
                autoComplete="off"
              />
              <Search className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" />

              {suggestions.length > 0 && (
                <div className="absolute w-full mt-1 bg-slate-800 border border-slate-700 rounded-md shadow-lg max-h-60 overflow-y-auto z-50">
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className="px-4 py-3 hover:bg-slate-700 cursor-pointer text-gray-300"
                      onClick={() => {
                        setLocation(`${suggestion.city}, ${suggestion.state}, ${suggestion.country}`);
                        setSuggestions([]);
                      }}
                    >
                      {suggestion.city}, {suggestion.state}, {suggestion.country}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {location && (
              <Button
                onClick={nextStep}
                className="mt-8 bg-teal-400 hover:bg-teal-500 text-white font-medium rounded-full px-12 py-6 w-full"
              >
                Continuar
              </Button>
            )}
          </div>
        );

      case 5: // Informação sobre Lua
        return (
          <div className="w-full max-w-md flex flex-col items-center">
            <h1 className="text-2xl md:text-3xl text-amber-100 font-serif text-center mb-16">
              Sabemos como a lua afeta sua energia!
            </h1>

            <div className="flex justify-center gap-16 mb-16">
              <div className="flex flex-col items-center">
                <div className="w-24 h-24 mb-3">
                  <img
                    src={lua1}
                    alt="Lua minguante"
                    className="rounded-full bg-gray-200"
                  />
                </div>
                <span className="text-gray-300 text-sm">Lua minguante</span>
              </div>

              <div className="flex flex-col items-center">
                <div className="w-24 h-24 mb-3">
                  <img
                    src={lua2}
                    alt="Lua crescente"
                    className="rounded-full bg-gray-200"
                  />
                </div>
                <span className="text-gray-300 text-sm">Lua crescente</span>
              </div>
            </div>

            <div className="w-full space-y-8 mb-16">
              <div className="flex gap-4">
                <span className="text-amber-100 text-3xl font-serif">75%</span>
                <p className="text-gray-300 text-sm">
                  das pessoas tendem a sentir uma
                  <span className="text-white"> diminuição da energia e do desempenho</span> na fase da lua minguante
                </p>
              </div>

              <div className="flex gap-4">
                <span className="text-amber-100 text-3xl font-serif">56%</span>
                <p className="text-gray-300 text-sm">
                  das pessoas, diferentemente,
                  <span className="text-white"> experimentam alegria e inspiração</span> na fase de Lua Crescente
                </p>
              </div>
            </div>

            <Button
              onClick={nextStep}
              className="w-full max-w-md mt-8 bg-gradient-to-r from-teal-400 to-teal-500
                         hover:from-teal-500 hover:to-teal-600 text-white text-xl py-6"
            >
              Continuar
            </Button>
          </div>
        );

      case 6: // Status de Relacionamento
        return (
          <div className="w-full max-w-md flex flex-col items-center">
            <h1 className="text-2xl md:text-3xl text-amber-100 font-serif text-center mb-16">
              Para começar, conte-nos sobre a situação atual do seu relacionamento
            </h1>

            <div className="w-full space-y-3">
              {[
                { id: 'relationship', text: 'Em um relacionamento', emoji: '💕' },
                { id: 'breakup', text: 'Acabei de me separar/terminar', emoji: '💔' },
                { id: 'engaged', text: 'Noivo(a)', emoji: '👫' },
                { id: 'married', text: 'Casado(a)', emoji: '💍' },
                { id: 'searching', text: 'Procurando uma alma gêmea', emoji: '🏹' },
                { id: 'complicated', text: 'É complicado', emoji: '🤔' },
              ].map((option) => (
                <Card
                  key={option.id}
                  onClick={() => {
                    updateQuizData('relationship', option.id);
                    nextStep();
                  }}
                  className={`w-full p-4 flex items-center gap-3 cursor-pointer transition-colors
                    ${quizData.relationship === option.id
                      ? 'bg-slate-600 text-white'
                      : 'bg-slate-700/50 text-gray-300 hover:bg-slate-600/50'}
                  `}
                >
                  <span className="text-xl">{option.emoji}</span>
                  <span>{option.text}</span>
                </Card>
              ))}
            </div>
          </div>
        );

      case 7: // Metas para o Futuro
        return (
          <div className="w-full max-w-2xl flex flex-col items-center">
            <h1 className="text-2xl md:text-3xl text-amber-100 font-serif text-center mb-4">
              Quais são suas metas para o futuro?
            </h1>

            <p className="text-gray-400 mb-8">
              Selecionados: {quizData.goals.length}/3
            </p>

            <div className="flex flex-wrap gap-3 justify-center max-w-xl mb-16">
              {[
                { id: 'family', text: 'Harmonia familiar', emoji: '🖤' },
                { id: 'career', text: 'Carreira', emoji: '💼' },
                { id: 'health', text: 'Saúde', emoji: '🔸' },
                { id: 'marriage', text: 'Casamento', emoji: '⚡' },
                { id: 'travel', text: 'Viajando pelo mundo', emoji: '🌍' },
                { id: 'education', text: 'Educação', emoji: '🎓' },
                { id: 'friends', text: 'Amigos', emoji: '👥' }
              ].map((goal) => (
                <button
                  key={goal.id}
                  onClick={() => {
                    const currentGoals = quizData.goals;
                    if (currentGoals.includes(goal.id)) {
                      updateQuizData('goals', currentGoals.filter(g => g !== goal.id));
                    } else if (currentGoals.length < 3) {
                      updateQuizData('goals', [...currentGoals, goal.id]);
                    }
                  }}
                  className={`flex items-center gap-2 px-4 py-2 rounded-full transition-all
                    ${quizData.goals.includes(goal.id)
                      ? 'bg-slate-600 text-white'
                      : 'bg-slate-700/50 text-gray-300 hover:bg-slate-600/50'}
                  `}
                >
                  <span>{goal.emoji}</span>
                  <span>{goal.text}</span>
                </button>
              ))}
            </div>

            {quizData.goals.length > 0 && (
              <Button
                onClick={nextStep}
                className="bg-teal-400 hover:bg-teal-500 text-white font-medium rounded-full px-12 py-6"
              >
                Continuar
              </Button>
            )}
          </div>
        );

      case 8: // Quiromancia
        return (
          <div className="w-full max-w-md flex flex-col items-center">
            <h1 className="text-2xl md:text-3xl text-amber-100 font-serif text-center mb-16">
              Sabemos como ler seu destino com 95% de precisão!
            </h1>

            <div className="relative w-full aspect-square max-w-md mb-8">
              <img
                src={mao1}
                alt="Mão com linhas quiromancia"
                className="w-full h-full object-contain"
              />
            </div>

            <Button
              onClick={nextStep}
              className="bg-teal-400 hover:bg-teal-500 text-white text-xl font-medium rounded-full px-12 py-6 w-full"
            >
              Continuar
            </Button>
          </div>
        );

      case 9: // Elemento da Natureza
        return (
          <div className="w-full max-w-md flex flex-col items-center">
            <h1 className="text-2xl md:text-3xl text-amber-100 font-serif text-center mb-4">
              De qual elemento da natureza você mais gosta?
            </h1>

            <p className="text-gray-300 text-center mb-16">
              O elemento da natureza é importante para uma melhor personalização
            </p>

            <div className="w-full space-y-3">
              {[
                { id: 'terra', name: 'Terra', icon: '🌱' },
                { id: 'agua', name: 'Água', icon: '💧' },
                { id: 'fogo', name: 'Fogo', icon: '🔥' },
                { id: 'ar', name: 'Ar', icon: '💨' }
              ].map((element) => (
                <Card
                  key={element.id}
                  onClick={() => {
                    updateQuizData('element', element.id);
                    nextStep();
                  }}
                  className={`w-full p-4 flex items-center gap-3 cursor-pointer transition-colors
                    ${quizData.element === element.id
                      ? 'bg-slate-600 text-white'
                      : 'bg-slate-700/50 text-gray-300 hover:bg-slate-600/50'}
                  `}
                >
                  <span className="text-2xl">{element.icon}</span>
                  <span>{element.name}</span>
                </Card>
              ))}
            </div>
          </div>
        );

      case 10: // Preferência de Cor
        return (
          <div className="w-full max-w-md flex flex-col items-center">
            <h1 className="text-2xl md:text-3xl text-amber-100 font-serif text-center mb-4">
              Qual das cores a seguir você prefere?
            </h1>

            <p className="text-gray-300 text-center mb-16">
              A cor é importante para uma melhor personalização
            </p>

            <div className="w-full space-y-3">
              {[
                { id: 'vermelho', name: 'Vermelho', color: '#FF6B6B' },
                { id: 'amarelo', name: 'Amarelo', color: '#FFD93D' },
                { id: 'azul', name: 'Azul', color: '#4D96FF' },
                { id: 'laranja', name: 'Laranja', color: '#FFB26B' },
                { id: 'verde', name: 'Verde', color: '#6BCB77' },
                { id: 'violeta', name: 'Violeta', color: '#B39DDB' }
              ].map((cor) => (
                <Card
                  key={cor.id}
                  onClick={() => {
                    updateQuizData('color', cor.id);
                    nextStep();
                  }}
                  className={`w-full p-4 flex items-center gap-3 cursor-pointer transition-colors
                    ${quizData.color === cor.id
                      ? 'bg-slate-600 text-white'
                      : 'bg-slate-700/50 text-gray-300 hover:bg-slate-600/50'}
                  `}
                >
                  <div
                    className="w-6 h-6 rounded-full"
                    style={{ backgroundColor: cor.color }}
                  />
                  <span>{cor.name}</span>
                </Card>
              ))}
            </div>
          </div>
        );
        case 11: {
          return (
            <div className="min-h-screen bg-slate-800 flex flex-col items-center px-4 py-6">
              <div className="w-full max-w-md flex flex-col items-center">
                <h2 className="text-2xl text-amber-100 font-serif text-center mb-8">
                  Tire uma foto da palma de sua mão esquerda
                </h2>

                {mode === 'initial' && (
                  <Card className="w-64 h-64 bg-slate-800/50 border-2 border-dashed border-slate-700/50 rounded-lg flex items-center justify-center mb-8">
                    <CardContent className="flex flex-col items-center justify-center p-6">
                      <div className="w-32 h-32 text-teal-400">
                        <svg viewBox="0 0 100 100" className="w-full h-full">
                          <path
                            d="M50,10 C60,10 70,15 75,25 C80,35 80,45 75,55 L65,75 L50,90 L35,75 L25,55 C20,45 20,35 25,25 C30,15 40,10 50,10"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                          />
                          <path
                            d="M50,10 L50,90 M35,30 L65,30 M30,50 L70,50 M35,75 L65,75"
                            stroke="currentColor"
                            strokeWidth="2"
                          />
                        </svg>
                      </div>
                    </CardContent>
                  </Card>
                )}

                {renderCamera()}

                {mode === 'preview' && (
                  <>
                    <div className="w-64 h-64 bg-slate-800 rounded-lg overflow-hidden mb-8">
                      <img
                        src={imgSrc}
                        alt="Palm preview"
                        className="w-full h-full object-cover"
                      />
                    </div>

                    {error ? (
                      <Alert className="bg-red-400/10 border-red-400/20 mb-4">
                        <AlertDescription className="text-red-400">
                          {error}
                        </AlertDescription>
                      </Alert>
                    ) : (
                      <Button
                        className="bg-teal-400 hover:bg-teal-500 text-slate-900 font-medium px-8 py-2 rounded-full flex items-center gap-2"
                        onClick={analyzePalm}
                        disabled={isAnalyzing}
                      >
                        {isAnalyzing ? (
                          <>
                            <Loader className="w-5 h-5 animate-spin" />
                            Analisando...
                          </>
                        ) : (
                          'Analisar Mão'
                        )}
                      </Button>
                    )}

                    <Button
                      className="mt-4 text-gray-400 hover:text-white"
                      onClick={resetAndStartCamera}
                    >
                      Tirar outra foto
                    </Button>
                  </>
                )}

                {mode === 'analysis' && (
                  <div className="w-full flex flex-col items-center">
                    <div className="w-64 h-64 bg-slate-800 rounded-lg overflow-hidden mb-8">
                      <img
                        src={imgSrc}
                        alt="Palm preview"
                        className="w-full h-full object-cover"
                      />
                    </div>

                    {palmAnalysis && (
                      <div className="w-full space-y-4">
                        <Alert className="bg-teal-400/10 border-teal-400/20">
                          <AlertDescription className="text-teal-400">
                            <h3 className="font-medium mb-2">Linhas Principais Detectadas:</h3>
                            <ul className="space-y-2">
                              {palmAnalysis.lifeLineDetected && (
                                <li>✨ Linha da Vida: Representa sua vitalidade e experiências de vida</li>
                              )}
                              {palmAnalysis.heartLineDetected && (
                                <li>💖 Linha do Coração: Indica aspectos emocionais e relacionamentos</li>
                              )}
                              {palmAnalysis.destinyLineDetected && (
                                <li>🌟 Linha do Destino: Mostra seu caminho e direção na vida</li>
                              )}
                              {palmAnalysis.headLineDetected && (
                                <li>🧠 Linha da Cabeça: Reflete seu pensamento e abordagem mental</li>
                              )}
                            </ul>
                          </AlertDescription>
                        </Alert>

                        <Button
                          className="mt-4 text-gray-400 hover:text-white"
                          onClick={resetAndStartCamera}
                        >
                          Tirar outra foto
                        </Button>
                      </div>
                    )}
                  </div>
                )}

                <div className="flex flex-col items-center gap-4 mt-8 w-full">
                  {mode === 'initial' && (
                    <>
                      <Button
                        className="bg-teal-400 hover:bg-teal-500 text-slate-900 font-medium px-8 py-2 rounded-full flex items-center gap-2"
                        onClick={startCamera}
                      >
                        <Camera className="w-5 h-5" />
                        Tirar foto
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleUpload}
                        id="fileInput"
                      />
                      <label
                        htmlFor="fileInput"
                        className="text-gray-400 hover:text-white cursor-pointer text-sm"
                      >
                        Enviar foto da palma da mão
                      </label>
                    </>
                  )}
                </div>

                <p className="text-gray-500 text-sm text-center max-w-xs mt-8">
                  Prezamos por sua privacidade. A rápida exclusão de dados não identificáveis garante o anonimato
                </p>
              </div>
            </div>
          );
        }
      default:
        return null;
    }
  };

  const renderCamera = () => {
    if (mode !== 'camera') return null;

    return (
      <div className="fixed inset-0 z-50 bg-slate-900">
        <video
          ref={videoRef}
          className="w-full h-full object-cover"
          playsInline
          autoPlay
        />

        {landmarks && <HandLandmarks landmarks={landmarks} />}

        <div className="absolute bottom-8 right-1/2 transform translate-x-1/2">
          <Button
            className="bg-teal-400 hover:bg-teal-500 text-slate-900 font-medium px-8 py-2 rounded-full flex items-center gap-2"
            onClick={capturePhoto}
          >
            <Camera className="w-5 h-5" />
            Capturar
          </Button>
        </div>

        {/* Botão para voltar - Agora reseta o case 11 */}
        <button
          onClick={resetAndStartCamera}
          className="absolute top-4 left-4 text-white p-2 rounded-full bg-slate-800/50 hover:bg-slate-700/50"
        >
          ←
        </button>
      </div>
    );
  };

  const resetAndStartCamera = () => {
    // Primeiro limpa tudo
    setMode('initial');
    setLandmarks(null);
    setIsHandDetected(false);
    setPalmAnalysis(null);
    setError(null);
    setImgSrc(null);

    // Força o recarregamento do case 11
    setCurrentStep(11);

    // Pequeno delay antes de iniciar a câmera novamente
    setTimeout(() => {
      setMode('camera');
    }, 100);
  };

  return (
    <div className="min-h-screen bg-slate-800 flex flex-col items-center px-4 py-6">
      {renderHeader()}
      {renderStep()}
    </div>
  );
};

export default Leitura;